import React, { ChangeEvent } from 'react';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import {
  List,
  Flex,
  Spinner,
  Button,
  Checkbox,
  FormLabel,
  EntityList,
  SkeletonContainer,
  SkeletonBodyText,
  Note,
  Tooltip,
  FormControl,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Badge,
  TextInput,
  Select,
} from '@contentful/f36-components';
import { PlusIcon, PageIcon, HelpCircleIcon } from '@contentful/f36-icons';
import {
  getSelections,
  getContentTypes,
  removeValueAndChildren,
} from '../../../utils/helpers';
import loGet from 'lodash.get';
import TrackChanges from '../parts/TrackChanges';
import { isMediaLinkType, MEDIA_LINK_TYPE } from '../../../utils/assetHelpers';
import { getSourceContentWithReferences } from '../../../utils/referenceEntryHelper';
import NewLocalisedReferences from '../parts/NestedLocalisedReferences';
import { getStatusBackgroundColor, getStatusColor } from '../../../config/translationModel';

interface Props {
  value: object[];
  trackChanges: boolean;
  handleFieldChange: Function;
  showNotice: Function;
  showTrackChanges: boolean;
  sdk: EditorExtensionSDK;
  showLinkedReference: boolean;
  selectedReferenceFields: string[];
  selectedEmbeddedReferences: string[];
  contentType: string;
  selectedContent: string[];
  excludedContent: string[];
}

interface State {
  selectedEntries: object[];
  contentTypes: object[];
  referenceFieldMap: any;
  newReferenceFields: object[];
  loading: boolean;
  checkedEntries: string[];
  totalEntries: number;
  filteredEntries: string[];
  searchTerm: string;
  selectedContentType: string;
  viewType: string;
}

export default class SourceContentField extends React.Component<Props, State> {
  state: State = {
    selectedEntries: [],
    contentTypes: [],
    referenceFieldMap: null,
    newReferenceFields: [],
    loading: true,
    checkedEntries: [],
    totalEntries: -1,
    filteredEntries: [],
    searchTerm: '',
    selectedContentType: '',
    viewType: 'all',
  };
  timeout: any = null;

  constructor(props: Readonly<Props>) {
    super(props);
    //initially set the value once
    this.props.handleFieldChange('trackChanges', this.props.trackChanges);
  }

  async componentDidMount() {
    await this.handleMount();
    this.props.sdk.entry.fields.localizationMethod.onValueChanged(this.updateSourceContent);
    this.props.sdk.entry.fields.sourceContent.onValueChanged(this.updateSourceContent);
    // if source content is made empty, remove checked entries
    this.props.sdk.entry.fields.sourceContent.onValueChanged((sourceContentValue: any) => {
      if (!sourceContentValue || sourceContentValue.length == 0) {
        this.setState({
          checkedEntries: [],
        });
      }
    });
    this.props.sdk.entry.fields.localizedReferences.onValueChanged(this.updateSourceContent);
    this.props.sdk.entry.fields.selectedReferenceFields.onValueChanged(this.updateSourceContent);
    this.props.sdk.entry.fields.embeddedReference.onValueChanged(this.updateSourceContent);
    this.props.sdk.entry.fields.selectedEmbeddedReferences.onValueChanged(this.updateSourceContent);
  }

  updateSelectedContentAndLinkedReference = async () => {
    this.props.handleFieldChange("selectedContent", []);
    this.props.handleFieldChange("excludedContent", []);
    this.setState({
      filteredEntries: []
    })
  }

  handleMount = async () => {
    const contentTypes = await getContentTypes(this.props.sdk);
    this.setState({
      contentTypes: contentTypes,
    });
    this.updateSourceContent();
  };

  removeEntries = async () => {
    let sourceContent = this.props.sdk.entry.fields.sourceContent.getValue();
    const selectedEntries = sourceContent.filter((filterSelectEntry: { sys: { id: any } }) => {
      return !this.state.checkedEntries.includes(filterSelectEntry.sys.id);
    });
    this.setState({
      checkedEntries: [],
    });
    await this.updateSelectedEntries(selectedEntries);
  };

  updateSelectedEntries = async (selectedEntries: any) => {
    this.props.handleFieldChange(
      'sourceContent',
      selectedEntries.map((selectedEntry: any) => {
        return {
          sys: {
            id: selectedEntry.sys.id,
            linkType: 'Entry',
            type: 'Link',
          },
        };
      }),
    );
  };

  openEntrySelector = async () => {
    const value = loGet(this, 'props.sdk.entry.fields.contentType', {
      getValue: () => null,
    }) as { getValue: () => any }; // Assuming this is the expected type
    const contentType = value.getValue();
    if (contentType) {
      this.props.sdk.dialogs
        .selectMultipleEntries({
          contentTypes: [contentType],
        })
        .then((selectedEntries: any) => {
          if (selectedEntries && selectedEntries.length) {
            // eslint-disable-next-line react/no-access-state-in-setstate
            let sourceContent = this.props.sdk.entry.fields.sourceContent.getValue() || [];
            for (const selectedEntry of selectedEntries) {
              const existingEntry = sourceContent.find((stateEntry: { sys: { id: any } }) => {
                return stateEntry.sys.id == selectedEntry.sys.id;
              });
              if (!existingEntry) {
                sourceContent.push(selectedEntry);
              }
            }
            this.updateSelectedEntries(sourceContent);
          }
        });
    } else {
      this.props.sdk.notifier.error('Select content type first');
    }
  };

  getContentType = (selectedEntry: any) => {
    const contentType: any =
      this.state.contentTypes.find((contentType: any) => {
        return contentType.sys.id === selectedEntry.contentType;
      }) || {};
    return contentType.name;
  };

  openEntryEditor = (selectedEntry: any) => {
    const entryId = selectedEntry.entryId;
    if (isMediaLinkType(selectedEntry.type)) {
      this.props.sdk.navigator.openAsset(entryId, { slideIn: true });
    } else {
      this.props.sdk.navigator.openEntry(entryId, { slideIn: true });
    }
  };

  updateSelected = async (value: string) => {
    let selectedReferenceFields = [...this.props.selectedReferenceFields]; // Make a copy to avoid mutating props directly
    if (selectedReferenceFields.includes(value)) {
      let linkedContentTypes = await this.props.sdk.entry.fields['linkedContentTypes'].getValue();
      await removeValueAndChildren(value, selectedReferenceFields, linkedContentTypes);
    } else {
      selectedReferenceFields.push(value);
    }
    this.props.handleFieldChange('selectedReferenceFields', selectedReferenceFields);
  };

  updateSourceEntries = async (response: any) => {
    let finalSourceContent = [];

    // Extract embeddedEntryIds, sourceContent, and sourceContentIds
    const sourceContent = await this.props.sdk.entry.fields.sourceContent.getValue() || [];
    const sourceContentIds = sourceContent.map((item: any) => item.sys.id);

    // Get embeddedReference and localizedReference
    const selectedEmbeddedReference = this.props.sdk.entry.fields.selectedEmbeddedReferences.getValue() || [];
    const embeddedReference = await this.props.sdk.entry.fields.embeddedReference.getValue() || false;
    const localizedReferences = await this.props.sdk.entry.fields.localizedReferences.getValue() || false;

    // Filter source entries based on the given conditions
    finalSourceContent = response.sourceEntries.filter((entry: any) => {
      const isInSourceContent = sourceContentIds.includes(entry.entryId);

      // Find the matching embedded entry
      const embeddedEntry = response.embeddedEntriesIds.find((embedded: any) => embedded.sys.id === entry.entryId);
      const isEmbeddedAndSelected = embeddedEntry
        ? selectedEmbeddedReference.includes(embeddedEntry.sys.embeddType)
        : false;

      // Handle entries that are both embedded and localized
      if (entry.isEmbeddedEntry && entry.isLocalized) {
        // Prioritize localized reference first
        if (localizedReferences) {
          return true; // Include if localized reference is true, ignore the embedded type
        }
        // If localized reference is false, check embedded reference
        return embeddedReference && isEmbeddedAndSelected;
      }

      // Include based on localizedReference and embeddedReference when entry is not both
      if (entry.isLocalized && localizedReferences) {
        return true; // Include if localized reference is true
      }

      if (entry.isEmbeddedEntry && embeddedReference) {
        return isEmbeddedAndSelected; // Include based on selected embedded type
      }

      // Always include entries in sourceContentIds
      if (isInSourceContent) {
        return true;
      }

      // If none of the above conditions apply, exclude the entry
      return false;
    });

    // Remove duplicates by entryId
    finalSourceContent = finalSourceContent.filter(
      (entry: any, index, self) => index === self.findIndex((e: any) => e.entryId === entry.entryId)
    );

    return finalSourceContent;
  };

  updateSourceContent = () => {
    this.setState({
      loading: true,
    });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      getSourceContentWithReferences(
        this.props.sdk,
        this.props.showLinkedReference,
        this.props.selectedReferenceFields,
        [
          "embedded-asset-block",
          "embedded-entry-block",
          "embedded-entry-inline"
        ],
      )
        .then(async (response: any) => {
          clearTimeout(this.timeout);
          this.updateSourceEntries(response).then(async (finalSourceEntries: any) => {
            const selectedContent = this.props.selectedContent || [];
            const excludedContent = this.props.excludedContent || [];
            const sourceContent = this.props.sdk.entry.fields.sourceContent.getValue() || [];

            // Filter new entries that are neither in selectedContent nor excludedContent
            const newEntries = finalSourceEntries.filter((entry: any) => {
              const isAlreadySelected = selectedContent.some(
                (selectedEntry: any) => selectedEntry === entry.entryId || selectedEntry.sys?.id === entry.entryId
              );
              const isExcluded = excludedContent.includes(entry.entryId);
              // Only include if neither selected nor excluded
              return !isAlreadySelected && !isExcluded;
            });

            // Add entries from sourceContent (with sys.id) that are in finalSourceEntries to filteredEntries
            const sourceEntriesFromSourceContent = finalSourceEntries.filter((entry: any) => {
              return sourceContent.some(
                (sourceEntry: any) => sourceEntry.sys?.id === entry.entryId
              );
            });

            // Combine selectedContent and new entries into filteredEntries
            let filteredEntries = [
              ...selectedContent.map((entry: any) => (entry)), // Map sys.id from selectedContent if needed
              ...newEntries.map((entry: any) => entry.entryId),
              ...sourceEntriesFromSourceContent.map((entry: any) => entry.entryId) // Include sourceContent entries
            ];

            // Ensure filteredEntries contains only unique entryIds
            filteredEntries = Array.from(new Set(filteredEntries));

            // Remove matched entries from excludedContent
            const updatedExcludedContent = excludedContent.filter(
              (excludedId: string) => !filteredEntries.includes(excludedId)
            );

            // Update selectedContent to include new filtered entries
            const updatedSelectedContent = [
              ...new Set([
                ...filteredEntries
              ])
            ];

            // Update the state with the new filtered entries and source data
            this.setState({
              selectedEntries: finalSourceEntries, // Update with the latest entries from the response
              referenceFieldMap: response.referenceFieldMap,
              newReferenceFields: response.localisedReferences,
              loading: false,
              totalEntries: -1,
              filteredEntries, // Now contains selectedContent, new entries, and sourceContent entries,
              viewType: 'all',
              selectedContentType: ''
            });

            // Update SDK fields with the new values
            this.props.handleFieldChange('selectedContent', updatedSelectedContent); // Update selectedContent
            this.props.handleFieldChange('excludedContent', updatedExcludedContent); // Update excludedContent
            await this.props.handleFieldChange('linkedContentTypes', response.linkedContentTypes); // Update linked content types
          });
        })
        .catch((err) => {
          if (this.props.sdk.entry.fields.sourceContent.getValue().length > 0) {
            this.props.handleFieldChange('sourceContent', []);
          }
          this.setState({
            selectedEntries: [],
            referenceFieldMap: [],
            newReferenceFields: [],
            loading: false,
            totalEntries: -1,
          });
          console.log(err);
          this.props.showNotice(err.data);
        });
    }, 10);
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      showLinkedReference,
      contentType,
      selectedReferenceFields,
      selectedEmbeddedReferences
    } = this.props;

    // Check if `showLinkedReference` has changed
    if (prevProps.showLinkedReference !== showLinkedReference) {
      this.updateSourceContent();
    }

    // Check if `viewType` or `selectedContentType` has changed
    if (prevState.viewType !== this.state.viewType || prevState.selectedContentType !== this.state.selectedContentType) {
      this.setState({ checkedEntries: [] });
    }

    // Check if `selectedReferenceFields` or `selectedEmbeddedReferences` have changed
    if (
      !this.arraysEqual(prevProps.selectedReferenceFields, selectedReferenceFields) ||
      !this.arraysEqual(prevProps.selectedEmbeddedReferences, selectedEmbeddedReferences) ||
      (prevProps.contentType !== contentType)
    ) {
      this.updateSelectedContentAndLinkedReference();
    }
  }

  // Helper function to compare two arrays for equality
  arraysEqual(array1: string[], array2: string[]): boolean {
    if (array1.length !== array2.length) return false;
    return array1.every((value, index) => value === array2[index]);
  }

  updateCheckedEntries = (value: string, all = false, currentEntries: any[] = []) => {
    this.setState((state) => {
      return {
        checkedEntries: getSelections(
          state.checkedEntries,
          currentEntries,
          value,
          all,
          (e: any) => e.entryId,
        ),
      };
    });
  };

  selectAllEntries = async () => {
    this.setState({ loading: true });
    let limit = 100; // Starts giving response size too large after this
    let skip = 0;
    let selectedEntries: any[] = [];

    while (skip < this.state.totalEntries) {
      let resources = await this.getEntries(limit, skip);
      selectedEntries.push(...resources.items);
      skip += resources.items.length;
    }

    let sourceContent = this.props.sdk.entry.fields.sourceContent.getValue() || [];
    for (const selectedEntry of selectedEntries) {
      const existingEntry = sourceContent.find((stateEntry: { sys: { id: any } }) => {
        return stateEntry.sys.id == selectedEntry.sys.id;
      });
      if (!existingEntry) {
        sourceContent.push(selectedEntry);
      }
    }
    this.updateSelectedEntries(sourceContent);
  };

  setEntriesCount = async (contentTypeValue: string) => {
    let entryCounts = contentTypeValue ? (await this.getEntries(1, 0)).total : -1;
    if (this.state.totalEntries != entryCounts) {
      this.setState({ totalEntries: entryCounts });
    }
  };

  getEntries = async (limit: number, skip: number) => {
    return await this.props.sdk.cma.entry.getMany({
      query: {
        content_type: this.props.sdk.entry.fields['contentType'].getValue(),
        limit: limit,
        skip: skip,
      },
    });
  };

  areAllCheckedEntriesInFilteredEntries = () => {
    return this.state.checkedEntries.every((entryId: any) => this.state.filteredEntries.includes(entryId)) || this.isParenEntriesSelected();
  };

  areNoCheckedEntriesInFilteredEntries = () => {
    return this.state.checkedEntries.every((entryId: any) => !this.state.filteredEntries.includes(entryId)) && !this.isParenEntriesSelected();
  };

  isParenEntriesSelected = () => {
    const parentEntries = this.getParentEntries();
    // Check if all checked entries are parent entries
    return this.state.checkedEntries.every((entryId: any) => parentEntries.includes(entryId));
  };

  getParentEntries = () => {
    const sourceContent = this.props.sdk.entry.fields.sourceContent.getValue() || [];
    const ids = sourceContent.map((item: any) => { return item.sys.id });
    let parentEntries = this.state.selectedEntries
      .filter((e: any) => {
        return ids.includes(e.entryId)
      })
      .map((e: any) => e.entryId);
    return parentEntries;
  }


  hasNonParentEntriesSelected = () => {
    const parentEntries = this.getParentEntries();
    return this.state.checkedEntries.some((entryId: any) => !parentEntries.includes(entryId));
  };

  hasNoParentEntriesSelected = () => {
    const parentEntries = this.getParentEntries();
    // Return true if none of the checked entries are parent entries
    return !this.state.checkedEntries.some((entryId: any) => parentEntries.includes(entryId));
  };

  hasParentEntriesAndIncludeExclude = () => {
    const parentEntries = this.getParentEntries();
    return parentEntries.length > 0 && this.state.checkedEntries.some((entryId: any) => !parentEntries.includes(entryId));
  };

  handleIncludeExclude = () => {
    const { checkedEntries, filteredEntries } = this.state;
    const excludedContent = this.props.sdk.entry.fields.excludedContent.getValue() || [];

    if (this.areAllCheckedEntriesInFilteredEntries()) {
      // Exclude: remove checked entries from filteredEntries and add to excludedContent
      const newFilteredEntries = filteredEntries.filter((entryId: any) => !checkedEntries.includes(entryId));
      const newExcludedContent = [...excludedContent, ...checkedEntries];

      this.setState(
        {
          filteredEntries: newFilteredEntries,
          checkedEntries: [],
        },
        () => {
          // Update both selectedContent (included entries) and excludedContent in the SDK
          this.props.handleFieldChange('selectedContent', this.state.filteredEntries);
          this.props.handleFieldChange('excludedContent', newExcludedContent);
        }
      );
    } else if (this.areNoCheckedEntriesInFilteredEntries()) {
      // Include: add checked entries to filteredEntries and remove from excludedContent
      const newFilteredEntries = [...filteredEntries, ...checkedEntries];
      const newExcludedContent = excludedContent.filter((entryId: any) => !checkedEntries.includes(entryId));

      this.setState(
        {
          filteredEntries: newFilteredEntries,
          checkedEntries: [],
        },
        () => {
          // Update both selectedContent (included entries) and excludedContent in the SDK
          this.props.handleFieldChange('selectedContent', this.state.filteredEntries);
          this.props.handleFieldChange('excludedContent', newExcludedContent);
        }
      );
    }
  };


  getContentTypeName = (contentType: string): string => {
    const contentTypeObj: any = this.state.contentTypes.find((type: any) => type.sys.id === contentType);
    return contentTypeObj ? contentTypeObj.name : 'Unknown Content Type';
  };

  render() {
    const { selectedEntries, checkedEntries, filteredEntries, contentTypes, searchTerm, selectedContentType, viewType } = this.state;
    const matchingContentTypes = contentTypes.filter((contentType: any) =>
      selectedEntries.some((entry: any) => entry.contentType === contentType.sys.id)
    );
    let parentEntries = this.getParentEntries();
    let hasContentTypeValue = this.props.sdk.entry.fields['contentType'].getValue();
    if (hasContentTypeValue !== undefined) {
      this.setEntriesCount(hasContentTypeValue);
    }
    // Filter the selectedEntries based on the search term and selected content type
    const filteredByContentTypeAndSearch = selectedEntries.filter((entry: any) => {
      const matchesSearch = searchTerm ? (entry.entryName.toLowerCase().includes(searchTerm.toLowerCase())) : true;
      const matchesContentType = selectedContentType ? entry.contentType.toLowerCase() === selectedContentType.toLowerCase() : true;
      return matchesSearch && matchesContentType;
    });
    // Create the three arrays for inclusion and exclusion logic
    const includedEntries = filteredByContentTypeAndSearch.filter((entry: any) => filteredEntries.includes(entry.entryId));
    const excludedEntries = filteredByContentTypeAndSearch.filter((entry: any) => !filteredEntries.includes(entry.entryId));
    const allEntries = [...includedEntries, ...excludedEntries];

    // Determine the data source based on viewType
    let displayEntries: object[] = [];
    switch (viewType) {
      case 'include':
        displayEntries = includedEntries;
        break;
      case 'exclude':
        displayEntries = excludedEntries;
        break;
      case 'all':
      default:
        displayEntries = allEntries;
        break;
    };

    return (
      <FormControl isRequired>
        <Flex flexDirection="row">
          <FormLabel htmlFor="sourceContent">
            {'Source content'} -{' '}
            {this.state.loading ? 'Loading..': includedEntries.length +" out of " +selectedEntries.length +" selected." }
          </FormLabel>{' '}
          <Tooltip
            content={
              'Selected entry count may vary from what is shown in select all button. As we ignore the entries that do not have any content for translations.'
            }
          >
            <HelpCircleIcon
              className={'tooltip-icon'}
              variant={'muted'}
              style={{ paddingLeft: '5px' }}
            />
          </Tooltip>
        </Flex>
        {this.props.showTrackChanges && (
          <TrackChanges
            trackChanges={this.props.trackChanges}
            handleFieldChange={this.props.handleFieldChange}
          />
				)}
				{this.state.newReferenceFields && this.state.newReferenceFields.length > 0 && (
          <NewLocalisedReferences
            newReferenceFields={this.state.newReferenceFields}
            handleFieldChange={this.props.handleFieldChange}
            updateSelected={this.updateSelected}
            selectedReferenceFields={this.props.selectedReferenceFields}
          />
        )}
        {this.state.loading && (
          <>
            <Flex marginBottom="spacingL">
              <Note>
                Please be patient your data is being prepared. This may take some time depending on
                number of entries selected.
              </Note>
            </Flex>
            <SkeletonContainer>
              <SkeletonBodyText numberOfLines={4} />
            </SkeletonContainer>
          </>
        )}
        {!this.state.loading && selectedEntries.length > 0 && (
          <>
            <div className={'source-content-button-container'}>
              <div>
                <Flex flexDirection="row" gap="16px">
                  {/* Search Bar */}
                  <TextInput
                    type="text"
                    value={searchTerm}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ searchTerm: e.target.value })}
                    placeholder="Search by entry name..."
                    className="w-auto"
                  />

                  {/* Filter by Content Type */}
                  <Select
                    value={selectedContentType}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => this.setState({ selectedContentType: e.target.value })}
                    className='w-auto'
                  >
                    <option value="">All Content Types</option>
                    {matchingContentTypes
                      .sort((a: any, b: any) => a.name.localeCompare(b.name)) // Sort alphabetically by 'name'
                      .map((contentType: any) => (
                        <option key={contentType.sys.id} value={contentType.sys.id}>
                          {contentType.name}
                        </option>
                      ))}
                  </Select>

                  {/* Filter by include Type */}
                  <Select
                    value={viewType}
                    className='w-auto'
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => this.setState({ viewType: e.target.value })}
                  >
                    <option value="all">All</option>
                    <option value="include">Included</option>
                    <option key={"exclude"} value={"exclude"}>
                      Excluded
                    </option>
                  </Select>
                </Flex>
              </div>
              {checkedEntries.length > 0 && (
                <>
                  {this.isParenEntriesSelected() && (
                    <Button
                      variant="negative"
                      size="small"
                      onClick={this.removeEntries}
                    >
                      Remove
                    </Button>
                  )}
                  {this.hasNoParentEntriesSelected() && (
                    <>
                      {this.areAllCheckedEntriesInFilteredEntries() && (
                        <Button
                          variant="negative"
                          size="small"
                          onClick={this.handleIncludeExclude}
                        >
                          Exclude
                        </Button>
                      )}
                      {this.areNoCheckedEntriesInFilteredEntries() && (
                        <Button
                          variant="positive"
                          size="small"
                          onClick={this.handleIncludeExclude}
                        >
                          Include
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>

            <div  className="source-content-list" style={{ resize: 'vertical', overflow: 'auto', height:"280px", border: '1px solid rgba(0, 0, 0, 0.1)'}}>
              {/* Table Section */}
              <EntityList className="source-content-list" style={{ overflowX: "auto" }}>
                <Table className="table-auto w-full">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          isChecked={displayEntries.length > 0 && checkedEntries.length === displayEntries.length}
                          onChange={(evt) => this.updateCheckedEntries(evt.target.value, true, displayEntries)}
                          id="select-all-checkbox"
                        />
                      </TableCell>
                      <TableCell className="px-4 py-2">Name</TableCell>
                      <TableCell className="px-4 py-2">Status</TableCell>
                      <TableCell className="px-4 py-2">Content Type</TableCell>
                      <TableCell className="px-4 py-2">Included</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {displayEntries.map((selectedEntry: any) => {
                      const isInFilteredEntries = filteredEntries.includes(selectedEntry.entryId);
                      return (
                        <TableRow
                          key={selectedEntry.entryId + 'parent'}
                          style={{ opacity: isInFilteredEntries ? 1 : 0.5 }}
                        >
                          <TableCell>
                            <Checkbox
                              key={selectedEntry.entryId + 'check'}
                              value={selectedEntry.entryId}
                              isChecked={checkedEntries.includes(selectedEntry.entryId)}
                              onChange={() => this.updateCheckedEntries(selectedEntry.entryId)}
                            />
                          </TableCell>
                          <TableCell className="border px-4 py-2" onClick={() => this.openEntryEditor(selectedEntry)} style={{ maxWidth: '200px', overflowWrap: 'break-word', whiteSpace: 'normal', textAlign: 'left' }}>
                            {selectedEntry.entryName || 'Entry'}
                          </TableCell>
                          <TableCell className="border px-4 py-2">
                            <Badge style={{
                              color: getStatusColor(selectedEntry.entryStatus),
                              fontSize: '.65rem',
                              display: 'inline-flex',
                              alignItems: 'center',
                              maxHeight: 'inherit',
                              backgroundColor: getStatusBackgroundColor(selectedEntry.entryStatus),
                            }}>
                              {selectedEntry.entryStatus}
                            </Badge>
                          </TableCell>
                          <TableCell className="border px-4 py-2">
                            {this.getContentTypeName(selectedEntry.contentType)}
                          </TableCell>
                          <TableCell className="border px-4 py-2">
                            {filteredEntries.includes(selectedEntry.entryId) ? 'Yes' : 'No'}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </EntityList>
            </div>
          </>
        )}

        <Flex flexDirection="row" marginTop="spacingM">
          <Button
            type="button"
            startIcon={<PlusIcon />}
            variant={'secondary'}
            onClick={this.openEntrySelector}
          >
            Add source entries
          </Button>
          <Flex marginLeft="spacingM">
            <Button
              isDisabled={!hasContentTypeValue || parentEntries.length > 0}
              startIcon={<PageIcon />}
              variant={'secondary'}
              onClick={this.selectAllEntries}
              size="medium"
            >
              {this.state.loading ? (
                <Spinner />
              ) : this.state.totalEntries >= 0 ? (
                `Add all ${this.state.totalEntries} entries`
              ) : (
                'No entries'
              )}
            </Button>
          </Flex>
        </Flex>
      </FormControl>
    );
  }
}
