import React, { ChangeEvent } from 'react';
import {
  Button,
  Checkbox,
  Flex,
  HelpText,
  Modal,
  SkeletonBodyText,
  SkeletonContainer,
  Badge,
  TextLink,
} from '@contentful/f36-components';
import { ArrowUpIcon, ArrowDownIcon, DownloadIcon } from '@contentful/f36-icons';
import { INLINES } from '@contentful/rich-text-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import { Col, Row } from 'react-grid-system';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import loGet from 'lodash.get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { downloadEntry, slugify, splitEntryId } from '../../../utils/helpers';
import {
  getStatusBackgroundColor,
  getStatusColor,
  STATUS_IN_PROGRESS,
  STATUS_NEW,
  STATUS_PUBLISHED,
  STATUS_READY_REVIEW,
} from '../../../config/translationModel';
import CopyButton from '../parts/CopyButton';
import {
  getContentTypeFromEntry,
  getEntryName,
  requestChanges,
  updateEntry,
} from '../../../utils/contentful';
import NothingToShow from './NothingToShow';
import MoreButton from './MoreButton';
import ReactMarkdown from 'react-markdown';
import LinkIcon from './LinkIcon';
import { EntryContentStore } from '../../../../../lambda/src/utils/data-manager/data.manager';
import { getContentTypes, getFullEntities, getEntryAndAssetIds, getAssetURL, getEntryWebUrl, dateTimeFormat } from '../../../utils/helpers';

interface Props {
  sdk: EditorExtensionSDK;
  translationInfo: any;
  selectedEntries: string[];
  entryContentStore: EntryContentStore;
  entries: any[];
  showModal: boolean;
  closeModal: Function;
}

interface State {
  fullEntries: any[];
  allContentTypes: any[];
  localSelectedEntries: string[];
  moreButtonPressedOn: string;
  loading: boolean;
}

export default class EntryModal extends React.Component<Props, State> {
  state: State = {
    fullEntries: [],
    allContentTypes: [],
    localSelectedEntries: [],
    moreButtonPressedOn: '',
    loading: false,
  };

  async componentDidMount() {
    const fullEntries: any[] = await getFullEntities(
      this.props.sdk,
      getEntryAndAssetIds(this.props.selectedEntries),
    );
    const allContentTypes = await getContentTypes(this.props.sdk);
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      fullEntries,
      allContentTypes,
    });
  }

  updateEntry = async (localSelectedEntries: any[], publish = false) => {
    const { sdk, entries } = this.props;
    const { allContentTypes } = this.state;
    this.setState({
      loading: true,
    });
    await updateEntry(localSelectedEntries, entries, sdk, allContentTypes, publish);
    this.setState({
      loading: false,
    });
  };

  publishEntry = async (localSelectedEntries: any[]) => {
    await this.updateEntry(localSelectedEntries, true);
  };

  requestChanges = async (entryId: string) => {
    const { sdk } = this.props;
    await requestChanges(entryId, sdk);
    this.updateMoreButton();
  };

  updateMoreButton = (id = '') => {
    this.setState({ moreButtonPressedOn: id });
  };

  /**
   * Render node for entry/asset hyperlink as hyperlink
   *
   * This is needed otherwise the link will be rendered as plain text
   * @param nodeType
   * @param node
   */
  renderNode = (nodeType: string, node: any) => {
    let url = '';
    let id = loGet(node, 'data.target.sys.id');
    if (nodeType === INLINES.ASSET_HYPERLINK) {
      // Need to add full url as adding just id will create a link to entry with asset id
      url = getAssetURL(this.props.sdk);
    } else if (nodeType === INLINES.ENTRY_HYPERLINK) {
      url = getEntryWebUrl(this.props.sdk);
    }
    let cloneNode = { ...node };
    cloneNode.data = {uri: `${url}${id}`};
    cloneNode.nodeType = 'hyperlink';
    return documentToReactComponents(cloneNode);
  };

  extractSourceAndTargetContent = (
    sourceEntryFields: any,
    content: any,
    field: any,
    fieldType: any,
  ) => {
    let translatedContent: any = '';
    let originalContent: any = '';
    try {
      if (loGet(fieldType, 'type') == 'Text') {
        translatedContent = <ReactMarkdown>{content[field]}</ReactMarkdown>;
        originalContent = <ReactMarkdown>{sourceEntryFields[field]}</ReactMarkdown>;
      } else if (loGet(fieldType, 'type') == 'Object') {
        translatedContent = 'Object type not rendered';
        originalContent = 'Object type not rendered';
      } else if (loGet(sourceEntryFields, field + '.nodeType') == 'document') {
        this.extractEntryHyperlinks(content[field]);
        const options = {
          renderNode: {
            [INLINES.EMBEDDED_ENTRY]: () => '',
            [INLINES.ENTRY_HYPERLINK]: (node: any) =>
              this.renderNode(INLINES.ENTRY_HYPERLINK, node),
            [INLINES.ASSET_HYPERLINK]: (node: any) =>
              this.renderNode(INLINES.ASSET_HYPERLINK, node),
          },
        };
        translatedContent = documentToReactComponents(content[field], options);
        originalContent = documentToReactComponents(sourceEntryFields[field], options);
      } else if (Array.isArray(loGet(sourceEntryFields, field, null))) {
        translatedContent = content[field].join(', ');
        originalContent = sourceEntryFields[field].join(', ');
      } else {
        translatedContent = content[field];
        originalContent = sourceEntryFields[field];
      }
    } catch (e) {
      translatedContent = originalContent = (
        <span style={{ color: 'red' }}>Error rendering content. Check input.</span>
      );
    }
    return [translatedContent, originalContent];
  };

  /**
   * Extract links in case of inline entry/asset
   * @param node
   */
  extractEntryHyperlinks = (node: any) => {
    if (node?.nodeType && node.nodeType === 'hyperlink' && node.data.uri && (
      node.data.uri.startsWith('entry') || node.data.uri.startsWith('asset')
    )) {
      // Extract id and type from the URI
      const [type, id] = node.data.uri.split('##');
      const link = type === INLINES.ENTRY_HYPERLINK ? 'Entry' : 'Asset'
      // Update the node data
      node.data = {
        target: {
          sys: {
            id: id,
            type: 'Link',
            linkType: link
          }
        }
      };
      node.nodeType = type;
    }

    if (node.content && Array.isArray(node.content)) {
      node.content.forEach((childNode: any) => {
        this.extractEntryHyperlinks(childNode);
      });
    }
  }

  getEntryView = (selectableEntries: string[]) => {
    if (!this.state.allContentTypes.length) {
      return '';
    }
    const sourceEntries = loGet(this.props, 'entryContentStore.sourceEntries', []);
    const fileData = loGet(this.props, 'entryContentStore.fileData', []);

    if (this.state.loading) {
      return (
        <div
          style={{
            marginTop: '25px',
          }}
        >
          <SkeletonContainer>
            <SkeletonBodyText numberOfLines={4} />
          </SkeletonContainer>
        </div>
      );
    }

    const entryRows: any[] = [];
    let preExpanded: any[] = [];
    for (const entryId of this.props.selectedEntries) {
      const [contentfulId, targetLanguage] = splitEntryId(entryId);

      const entry = this.props.entries.find((storedEntry: any) => {
        return storedEntry.entryId == contentfulId && storedEntry.target == targetLanguage;
      });
      const fileEntry = fileData.find((storedEntry: any) => {
        return storedEntry.entryId == contentfulId && storedEntry.target == targetLanguage;
      });
      const entryStatus = entry.status || STATUS_NEW;

      // hide from modal if entry is published or in progress
      if (!selectableEntries.includes(entryId)) {
        continue;
      }

      const content = entry.content;
      if (!content) {
        continue;
      }

      const fullEntry = this.state.fullEntries.find(
        (fullEntry: any) => fullEntry.sys.id == contentfulId,
      );

      const contentType: any =
        this.state.allContentTypes.find((aContentType: any) => {
          return aContentType.sys.id === getContentTypeFromEntry(fullEntry);
        }) || {};

      const sourceEntry = sourceEntries.find((sourceEntry: { entryId: any }) => {
        return sourceEntry.entryId == contentfulId;
      });

      const entryRow: any[] = [];
      const sourceEntryFields = sourceEntry.fields;
      for (const field in content) {
        if (field == 'id' || !sourceEntryFields[field]) {
          continue;
        }
        const fieldType = contentType.fields.find((contentField: any) => {
          return contentField.id == field;
        });
        let translatedContent: any = '';
        let originalContent: any = '';

        if (
          loGet(sourceEntryFields, field + '.0.sys.type', false) ||
          loGet(sourceEntryFields, field + '.sys.type', false)
        ) {
          // skipping reference links
          continue;
        } else {
          [translatedContent, originalContent] = this.extractSourceAndTargetContent(
            sourceEntryFields,
            content,
            field,
            fieldType,
          );
        }
        const sourceId = Math.random() * 10000000 + '-source';
        const translatedId = Math.random() * 10000000 + '-translated';
        entryRow.push(
          <Row key={field} className={'padding-cell'}>
            <Col xs={6}>
              <HelpText className="modal-entry-name">
                {fieldType.name}
                <CopyButton sdk={this.props.sdk} contentId={sourceId} />
              </HelpText>
              <div id={sourceId}>{originalContent}</div>
            </Col>
            <Col xs={6} style={{ background: '#F7F9FA' }}>
              <HelpText className="modal-entry-name">
                {fieldType.name}
                <CopyButton sdk={this.props.sdk} contentId={translatedId} />
              </HelpText>
              <div id={translatedId}>{translatedContent}</div>
            </Col>
          </Row>,
        );
      }
      const isEntrySelected = this.state.localSelectedEntries.includes(entryId);
      const entryName = getEntryName(
        fullEntry,
        targetLanguage,
        this.state.allContentTypes,
        this.props.sdk,
      );
      const entrySlug = slugify(`${entryName}-${targetLanguage}`) + '.json';
      preExpanded.push(entryId);
      entryRows.push(
        <AccordionItem
          uuid={entryId}
          key={entryId}
          style={{
            marginTop: '25px',
            boxShadow: isEntrySelected
              ? 'inset 0px -2px 3px rgba(0, 0, 0, 0.08)'
              : '0px 2px 3px rgba(0, 0, 0, 0.08)',
            border: isEntrySelected ? '1px solid #2E75D4' : '1px solid #D3DCE0',
          }}
        >
          <div style={{ padding: '10px', borderBottom: '1px solid lightgray' }}>
            <div className="entry-modal-head-container">
              <Checkbox
                style={{ marginRight: '0.3rem' }}
                value={entryId}
                isChecked={isEntrySelected}
                onChange={() => {
                  this.updateEntrySelection(entryId, selectableEntries);
                }}
              />
              <AccordionItemHeading className={'entry-table-head'}>
                <AccordionItemButton>
                  <div className={'accordion-entry-name'}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div>
                        {entryName}{' '}
                        <LinkIcon
                          translationInfo={this.props.translationInfo}
                          entryId={entry.entryId}
                          sdk={this.props.sdk}
                        />
                      </div>
                      <Badge
                        className={'entry-modal-tag'}
                        style={{
                          color: getStatusColor(entryStatus),
                          backgroundColor: getStatusBackgroundColor(entryStatus),
                        }}
                      >
                        {entryStatus}
                      </Badge>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <span className={'last-delivered'}>
                        {entry.timeDelivered
                          ? 'Last delivered: ' +
                          dateTimeFormat(entry.timeDelivered, this.props.sdk.locales.default)
                          : ''}
                      </span>
                      <AccordionItemState>
                        {({ expanded }) =>
                          expanded ? (
                            <ArrowUpIcon variant={'muted'} />
                          ) : (
                            <ArrowDownIcon variant={'muted'} />
                          )
                        }
                      </AccordionItemState>
                    </div>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <MoreButton
                moreButtonPressedOn={this.state.moreButtonPressedOn}
                entryId={entryId}
                requestChanges={this.requestChanges}
                updateEntry={this.updateEntry}
                publishEntry={this.publishEntry}
                updateMoreButton={this.updateMoreButton}
                entryContentStore={this.props.entryContentStore}
                status={entryStatus}
                sdk={this.props.sdk}
                entrySlug={entrySlug}
              />
            </div>
            <div className={'modal-label'}>
              {entrySlug}{' '}
              <TextLink
                onClick={async () => {
                  await downloadEntry(
                    fileEntry,
                    entrySlug,
                    this.props.sdk,
                    this.props.entryContentStore,
                  );
                }}
              >
                <DownloadIcon variant={'muted'} />
              </TextLink>
            </div>
          </div>
          <AccordionItemPanel>{entryRow}</AccordionItemPanel>
        </AccordionItem>,
      );
    }

    return (
      <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={preExpanded}>
        {' '}
        {entryRows}{' '}
      </Accordion>
    );
  };

  updateEntrySelection = (value: string, selectableEntries: string[], all = false) => {
    this.setState((state) => {
      let selections = state.localSelectedEntries;
      if (all) {
        if (selections.length === selectableEntries.length) {
          selections = [];
        } else {
          selections = [...selectableEntries];
        }
      } else {
        const index = selections.indexOf(value);
        if (index === -1) {
          selections.push(value);
        } else {
          selections.splice(index, 1);
        }
      }
      return {
        localSelectedEntries: selections,
      };
    });
  };

  render() {
    let entryCount: number, publishedEntries: number, noShow: number;
    entryCount = publishedEntries = noShow = 0;
    let selectableEntries: string[] = [];
    // calculate if the entries have been "all published" or "there is nothing to show"
    this.props.selectedEntries.forEach((entryId: string) => {
      entryCount++;
      const [contentfulId, targetLanguage] = splitEntryId(entryId);

      const entry = loGet(this.props, 'entries', []).find((storedEntry: any) => {
        return storedEntry.entryId == contentfulId && storedEntry.target == targetLanguage;
      });
      const entryStatus = entry.status || STATUS_NEW;

      if ([STATUS_PUBLISHED, STATUS_IN_PROGRESS].includes(entryStatus)) {
        noShow++;
      } else {
        selectableEntries.push(entryId);
      }
      if ([STATUS_PUBLISHED].includes(entryStatus)) {
        publishedEntries++;
      }
    });

    let allPublished = entryCount == publishedEntries;
    let noEntriesToShow = entryCount == noShow;

    return (
      <Modal
        title={'Review and publish'}
        shouldCloseOnEscapePress={true}
        shouldCloseOnOverlayClick={true}
        size="fullWidth"
        position="top"
        modalContentProps={{
          className: 'review-entry-modal-content',
        }}
        isShown={this.props.showModal}
        onClose={() => {
          this.props.closeModal();
        }}
      >
        {(noEntriesToShow || allPublished) && <NothingToShow allPublished={allPublished} />}
        {!(noEntriesToShow || allPublished) && (
          <>
            <Flex
              justifyContent="space-between"
              style={{
                alignItems: 'center',
                border: '1px solid lightgrey',
                height: '60px',
                paddingRight: '8px',
              }}
            >
              <div style={{ display: 'inline-flex' }}>
                <div style={{ margin: '0 0.5rem' }}>
                  <Checkbox
                    isChecked={this.state.localSelectedEntries.length == selectableEntries.length}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      return this.updateEntrySelection(evt.target.value, selectableEntries, true);
                    }}
                    id={'select-all'}
                  >
                    Select all
                  </Checkbox>
                </div>
                {this.state.localSelectedEntries.length > 0 && (
                  <span> {this.state.localSelectedEntries.length} Selected </span>
                )}
              </div>
              <div className={'align-center-tab lh-1'}>
                <Button
                  type="button"
                  variant={'secondary'}
                  isDisabled={this.state.localSelectedEntries.length < 1}
                  onClick={async () => {
                    await this.updateEntry(this.state.localSelectedEntries);
                    this.setState({
                      localSelectedEntries: [],
                    });
                  }}
                >
                  Approve changes
                </Button>
                &nbsp;
                <Button
                  type="button"
                  variant={'positive'}
                  isDisabled={this.state.localSelectedEntries.length < 1}
                  onClick={async () => {
                    const response = await this.props.sdk.dialogs.openConfirm({
                      title: 'Publish changes?',
                      message: 'Are you sure you want to publish selected entries?',
                      intent: 'positive',
                      confirmLabel: 'Yes!',
                      cancelLabel: 'No',
                    });
                    if (response) {
                      await this.publishEntry(this.state.localSelectedEntries);
                      this.setState({
                        localSelectedEntries: [],
                      });
                    }
                  }}
                >
                  Publish selected
                </Button>
              </div>
            </Flex>
            <div className="review-entry-container">{this.getEntryView(selectableEntries)}</div>
          </>
        )}
      </Modal>
    );
  }
}
