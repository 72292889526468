import React from 'react';
import { EditorExtensionSDK } from '../../extensions-sdk';
import { SectionHeading, Card, Badge, TextLink } from '@contentful/f36-components';
import { ExternalLinkIcon } from '@contentful/f36-icons';
import loGet from 'lodash.get';
import { Row, Col } from 'react-grid-system';
import {
  getPerStatusProjectInfo,
  getTotalWords,
  getTranslationProjectStatus,
} from '../../config/translationModel';
import { getViewAllLink } from '../../utils/contentful';
import DataManager from '../../utils/data.manager';
import { EntryContentStore } from '../../../../lambda/src/utils/data-manager/data.manager';

interface Props {
  sdk: EditorExtensionSDK;
}
interface State {
  projects: any;
  total: number;
}

export default class LinkedTranslationProjects extends React.Component<Props, State> {
  private entryContentStoreMap: EntryContentStore[] = [];
  state: State = {
    projects: [],
    total: 0,
  };

  async componentDidMount() {
    const { entry, parameters, space } = this.props.sdk;
    const response = await space.getEntries({
      links_to_entry: entry.getSys().id,
      limit: 3,
      //@ts-ignore
      content_type: parameters.installation.translationsModelId,
      'sys.publishedAt[exists]': true,
    });
    let entryContentStorePromise = response.items.map((project: any) => {
      return DataManager.get(project.sys.id);
    });
    this.entryContentStoreMap = await Promise.all(entryContentStorePromise);
    this.setState({
      projects: response.items,
      total: response.total,
    });
  }

  getProjectCompletion(translationInfo: any, entryContentStore: EntryContentStore) {
    const projectStatus = getPerStatusProjectInfo(translationInfo, entryContentStore);
    let totalPercentage = 0;
    for (let status in projectStatus) {
      totalPercentage += projectStatus[status]['percentage'];
    }
    return isNaN(totalPercentage) ? 0 : totalPercentage;
  }

  render() {
    if (!(this.state.projects.length > 0)) {
      return '';
    }
    const link = getViewAllLink(this.props.sdk);
    const locale = this.props.sdk.locales.default;
    return (
      <>
        <SectionHeading className="mt-2">Translation Projects</SectionHeading>
        <hr className="faded" />
        {this.state.projects.map((project: any, index: any) => {
          const translationInfo = loGet(project, `fields.translationInfo.${locale}`, {});
          const projectName = loGet(project, `fields.translationName.${locale}`, 'Untitled');
          const entryContentStore = this.entryContentStoreMap[index];
          const status = getTranslationProjectStatus(translationInfo, entryContentStore);
          return (
            <Card
              key={project.sys.id}
              style={{ marginBottom: 'var(--spacing-xs)', width: 'auto' }}
              onClick={() => {
                this.props.sdk.navigator.openEntry(project.sys.id, { slideIn: true });
              }}
            >
              <Row>
                <Col xs={8} style={{ fontSize: '14px', fontWeight: 600 }}>
                  {projectName}
                </Col>
                <Col xs={4}>
                  <Badge
                    className="entry-status"
                    style={{ color: status.color }}
                    testId="cf-ui-tag"
                  >
                    {status.text}
                  </Badge>
                </Col>
              </Row>
              <Row className="completion-info" style={{ marginTop: '.75rem' }}>
                <Col xs={6} className={'project-status'}>
                  {this.getProjectCompletion(translationInfo, entryContentStore)}% complete
                </Col>
                <Col xs={6} className={'project-status'} style={{ letterSpacing: 'normal' }}>
                  <span className={'status'}>
                    {getTotalWords(entryContentStore.fileData || [])} total words
                  </span>
                </Col>
              </Row>
            </Card>
          );
        })}
        {
          <div style={{ textAlign: 'right' }}>
            <TextLink target={'_blank'} href={link} icon={<ExternalLinkIcon />}>
              View all{' '}
            </TextLink>
          </div>
        }
      </>
    );
  }
}
